<template>
  <div>
    <v-card class="custom-buttons-holder">
      <v-container fluid>
        <v-row justify="center">
          <v-col cols="8">
            <v-row v-show="isActive('tab-1')">
              <v-col cols="4 mb-5" v-for="item in this.reports" :key="item.id"
                v-if="item.name !== null && item.reportTypeId == 1">
                <a href="#" class="underline-text justify-start hidden-text-paragraf" @click="fetchAllReports(item.id)">
                  <span v-if="item.name.length > 30" class="truncate" @mouseover="showFullName = item.id"
                    @mouseleave="showFullName = null"> {{ showFullName === item.id ? item.name : item.name.slice(0, 27)
                      +
                      '...'
                    }}</span>

                  <span v-else> {{ item.name }}</span>

                </a>
              </v-col>

            </v-row>
            <v-row v-show="isActive('tab-2')">
              <v-col cols="4 mb-5" v-for="item in this.reports" :key="item.id"
                v-if="item.name !== null && item.reportTypeId == 2">
                <a href="#" class="underline-text justify-start hidden-text-paragraf" @click="fetchAllReports(item.id)">
                  <span v-if="item.name.length > 30" class="truncate" @mouseover="showFullName = item.id"
                    @mouseleave="showFullName = null"> {{ showFullName === item.id ? item.name : item.name.slice(0, 27)
                      +
                      '...'
                    }}</span>
                  <span v-else> {{ item.name }}</span>
                </a>
              </v-col>
            </v-row>
            <div class="horizontal-line"></div>

            <template>
              <v-tabs fixed-tabs bg-color="indigo-darken-2" class="mt-5">
                <v-tab class="tab-item text-capitalize" @click="setActive('tab-1')">
                  Kurzliste
                </v-tab>
                <v-tab class="tab-item text-capitalize" @click="setActive('tab-2')">
                  Pflegeplan
                </v-tab>
              </v-tabs>
            </template>
            <div v-show="isActive('tab-1')">
              <div class="d-flex justify-space-between">
                <h5 class="kurz-liste-title">Kurzliste</h5>
                <div class="buttons">

                  <a href="#" class="underline-text" @click="exportTreeTypeReport">
                    <img class="custom-icon mr-3" src="../../../assets/images/icons/export.svg" />
                    <!-- <span class="mdi mdi-open-in-new custom-icon"></span> -->
                    <span>Baumart</span>
                  </a>
                </div>
              </div>
              <v-row class="berichte-row">
              </v-row>
              <v-row>
                <v-col cols="8" class="from-to-tree">
                  <div class="fields-gap">
                    <v-col class="pa-0">
                      <p class="custom-label">Von:</p>
                      <v-autocomplete v-model="fromTree" item-value="name" :items="treeList" label="Suche" outlined
                        :item-text="item => `${item.abbreviation} - ${item.name}`" clearable
                        hide-details></v-autocomplete>
                    </v-col>
                    <v-col class="pa-0">
                      <p class="custom-label">Bis:</p>
                      <v-autocomplete v-model="toTree" item-value="name" :items="treeList" label="Suche" outlined
                        :item-text="item => `${item.abbreviation} - ${item.name}`" clearable
                        hide-details></v-autocomplete>
                    </v-col>

                    <!-- <v-text-field  value="" prefix="Von |" outlined hide-details v-model="fromTree"></v-text-field> -->
                    <!-- <v-text-field   value="" prefix="Bis |" outlined hide-details v-model="toTree"></v-text-field> -->
                  </div>
                </v-col>
                <v-col cols="4">
                  <p for="colorType" class="custom-label">Farbe</p>
                  <v-select hide-details v-model="selectedColor" clearable outlined :items="colorOptions">
                  </v-select>
                </v-col>

              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-checkbox label="TechnischeData" v-model="technicalData"></v-checkbox>
                </v-col>
              </v-row>
              <h5 class="kurz-liste-title">Gruppieren</h5>
              <v-row class="berichte-row">
                <v-col cols="12" class="berichte-holder">
                  <v-col cols="3">
                    <v-checkbox label="BaumFarbe" v-model="treeColorGroup" hide-details></v-checkbox>
                  </v-col>
                </v-col>
              </v-row>
              <v-col cols="12" class="form-element-holder">
                <div class="report-buttons">
                  <v-btn type="submit" size="x-large" height="54px" color="#1db954" class="submit-btn"
                    @click="exportBtnClicked">
                    Exportieren
                  </v-btn>
                </div>
              </v-col>
            </div>
            <div v-show="isActive('tab-2')">
              <h5 class="kurz-liste-title">Pflegeplan</h5>
              <v-row class="berichte-row ">
                <v-col cols="12" class="berichte-holder">
                  <v-col cols="8" class="from-to-tree">
                    <div class="fields-gap">
                      <v-col class="pa-0">
                        <p class="custom-label">Von:</p>
                        <v-autocomplete v-model="pfFromTree" item-value="name" :items="treeList" label="Suche" outlined
                          :item-text="item => `${item.abbreviation} - ${item.name}`" clearable
                          hide-details></v-autocomplete>
                      </v-col>
                      <v-col class="pa-0">
                        <p class="custom-label">Bis:</p>
                        <v-autocomplete v-model="pfToTree" item-value="name" :items="treeList" label="Suche" outlined
                          :item-text="item => `${item.abbreviation} - ${item.name}`" clearable
                          hide-details></v-autocomplete>
                      </v-col>

                      <!-- <v-text-field value="" prefix="Von |" outlined hide-details v-model="pfFromTree"></v-text-field>
                      <v-text-field value="" prefix="Bis |" outlined hide-details v-model="pfToTree"></v-text-field> -->
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <p class="custom-label">Massnahme:</p>
                    <v-autocomplete label="Suche" v-model="workTypeId" :items="workTypes" item-value="id"
                      :item-text="item => `${item.abbreviation} - ${item.description}`" outlined clearable hide-details>
                    </v-autocomplete>
                  </v-col>

                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <div class="from-to-dates">
                    <p class="custom-label">Datum</p>

                    <div class="fields-gap">
                      <v-menu ref="menufromDate" v-model="menufromDate" :close-on-content-click="false"
                        transition="scale-transition" offset-y>
                        <template v-slot:activator="{ on }">
                          <v-text-field :value="fromDateToShow" append-icon="mdi-calendar-blank-outline" outlined
                            readonly clearable hide-details v-on="on"
                            :placeholder="currentDatePlaceholder"></v-text-field>
                        </template>
                        <v-date-picker v-model="fromDate" no-title>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menufromDate = false">
                            Cancel
                          </v-btn>
                          <v-btn text color="primary" @click="$refs.menufromDate.save(fromDate)">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>

                      <v-menu ref="menutoDate" v-model="menutoDate" :close-on-content-click="false"
                        transition="scale-transition" offset-y>
                        <template v-slot:activator="{ on }">
                          <v-text-field :value="toDateToShow" append-icon="mdi-calendar-blank-outline" outlined readonly
                            clearable hide-details v-on="on" :placeholder="currentDatePlaceholder"></v-text-field>
                        </template>
                        <v-date-picker v-model="toDate" no-title>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menutoDate = false">
                            Cancel
                          </v-btn>
                          <v-btn text color="primary" @click="$refs.menutoDate.save(toDate)">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex pa-0 mt-3">
                  <v-col cols="4">
                    <v-checkbox label="Offene Arbeiten" v-model="openWork"></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox label="Erledigte Arbeiten" v-model="completedWork"></v-checkbox>
                  </v-col>
                </v-col>
              </v-row>
              <h5 class="kurz-liste-title">Gruppieren</h5>
              <v-col cols="12" class="berichte-holder">
                <v-radio-group cols="12" v-model="selectedSortOption">
                  <v-row>
                    <v-col cols="3">
                      <v-radio label="Monate" value="monthCheckbox"></v-radio>
                    </v-col>
                    <v-col cols="3">
                      <v-radio label="PflanzJahr" value="sortPlantingYear"></v-radio>
                    </v-col>
                    <v-col cols="3">
                      <v-radio label="Massnahme" value="workTypeCheckbox"></v-radio>
                    </v-col>
                    <v-col cols="3">
                      <v-radio label="BaumID" value="baumId"></v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
              <v-col cols="12" class="form-element-holder">
                <div class="report-buttons">
                  <v-btn type="submit" size="x-large" height="54px" color="#1db954" class="submit-btn"
                    @click="exportPfBtnClicked">
                    Exportieren
                  </v-btn>
                </div>
              </v-col>

            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <CustomLoader v-show="isLoading" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import CustomLoader from "../../../components/custom_components/CustomLoader.vue";
import moment from "moment";
import { format } from 'date-fns';
export default {
  components: {
    CustomLoader
  },
  data() {
    return {
      isLoading: false,
      //reports 
      reports: [],
      //kurzliste
      inventoryId: null,
      fromTree: "",
      toTree: "",
      treeType: [],
      workTypes: [],
      treeList: [],
      selectedColor: null,
      technicalData: false,
      treeColorGroup: false,
      baumId: false,
      workTypeCheckbox: false,
      colorOptions: [
        { text: 'Rot', value: 'red' },
        { text: "Blau", value: "blue" },
        { text: "Grün", value: "green" },
        { text: "Gelb", value: "yellow" },
        { text: "Grau", value: "gray" },
      ],
      //tabs
      activeTab: 'tab-1',
      //pflegenplan
      pfFromTree: "",
      pfToTree: "",
      menufromDate: false,
      menutoDate: false,
      fromDate: "",
      toDate: "",
      openWork: false,
      completedWork: false,
      taskSortPlantingYear: false,
      taskSortHeight: false,
      taskSelectedTreeTypeId: null,
      workTypeId: null,
      showFullName: null,
      selectedSortOption: "monthCheckbox",
      monthCheckbox: false,
      currentDatePlaceholder: format(new Date(), "dd-MM-yyyy"),
    };
  },
  computed: {
    ...mapGetters(["inventarId"]),
    fromDateToShow() {
      return this.fromDate ? moment(this.fromDate).format("DD-MM-YYYY") : "";
    },
    toDateToShow() {
      return this.toDate ? moment(this.toDate).format("DD-MM-YYYY") : "";
    },

  },
  methods: {
    setActive(tab) {
      this.activeTab = tab;
    },
    isActive(tab) {
      return this.activeTab === tab;
    },
    getAllTreeType() {
      axios
        .get(`https://tilia-ch.rrota.org/api/TreeType/GetAll/`)
        .then((response) => {
          // Handle the response from the server
          this.treeType = response.data
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //TREE REPORT
    async fetchTreeReport() {
      this.isLoading = true;
      const paramString = this.createParamString();
      try {
        const response = await axios.get(
          `https://tilia-ch.rrota.org/api/Report/TreeReport/${paramString}`
        ).then(response => {
          this.isLoading = false;
          var win = window.open();
          win.document.write('<iframe src="' + response.data + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
        }).catch(console.error);
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        this.hasError = true;
      }
    },
    exportBtnClicked() {
      this.fetchTreeReport();
    },
    createParamString() {
      const params = [];
      if (
        this.fromTree !== null &&
        this.fromTree !== undefined &&
        this.fromTree !== ""
      ) {
        params.push(`FromTree=${this.fromTree}`);
      }

      if (
        this.toTree !== null &&
        this.toTree !== undefined &&
        this.toTree !== ""
      ) {
        params.push(`ToTree=${this.toTree}`);
      }
      if (this.technicalData === true) {
        params.push(`TechnicalDatas=true`);
      }
      if (
        this.selectedColor !== null &&
        this.selectedColor !== undefined &&
        this.selectedColor !== ""
      ) {
        params.push(`ColorType=${this.selectedColor}`);
      }
      if (this.treeColorGroup === true) {
        params.push(`TreeGroup.ColorType=true`);
      }
      return (
        this.inventarId +
        "?" +
        params.join("&")
      );
    },
    async fetchWorkTypes(name) {
      var endpoint = `https://tilia-ch.rrota.org/api/WorkType/GetAll`;
      if (name) {
        endpoint += `?name=${name}`;
      }
      try {
        const response = await axios.get(endpoint);
        this.workTypes = response.data;
        this.isLoading = false;
        this.hasError = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
        this.showAlert = true;
      }
    },
    async getAllTreeList(name) {
      var endpoint = `https://tilia-ch.rrota.org/api/Tree/GetAllWithParameters/${this.inventarId}`;
      if (name) {
        endpoint += `?name=${name}`;
      }
      try {
        const response = await axios.get(endpoint);
        this.treeList = response.data;
        this.isLoading = false;
        this.hasError = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
        this.showAlert = true;
      }
    },

    //TASK REPORT
    async fetchTaskReport() {
      this.isLoading = true;
      const paramString = this.createTaskParamString();
      try {
        const response = await axios.get(
          `https://tilia-ch.rrota.org/api/Report/TaskReport/${paramString}`
        ).then(response => {
          this.isLoading = false;
          var win = window.open();
          win.document.write('<iframe src="' + response.data + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
          // const blob = new Blob([response.data], { type: 'application/pdf' })
          // const link = document.createElement('a')
          // link.href = URL.createObjectURL(blob)
          // link.target = '_blank';
          // link.download = 'Pflegeplan.pdf';
          // link.click()
          // document.body.appendChild(link);
        }).catch(console.error);
      }
      catch (error) {
        this.isLoading = false;
        console.log(error);
        this.hasError = true;
      }
    },
    exportPfBtnClicked() {
      this.fetchTaskReport();
    },
    createTaskParamString() {
      const params = [];
      if (
        this.pfFromTree !== null &&
        this.pfFromTree !== undefined &&
        this.pfFromTree !== ""
      ) {
        params.push(`FromTree=${this.pfFromTree}`);
      }
      if (
        this.pfToTree !== null &&
        this.pfToTree !== undefined &&
        this.pfToTree !== ""
      ) {
        params.push(`ToTree=${this.pfToTree}`);
      }
      if (
        this.workTypeId !== null &&
        this.workTypeId !== undefined &&
        this.workTypeId !== ""
      ) {
        params.push(`WorkTypeId=${this.workTypeId}`);
      }
      if (
        this.fromDate !== null &&
        this.fromDate !== undefined &&
        this.fromDate !== ""
      ) {
        params.push(`FromDate=${this.fromDate}`);
      }
      if (
        this.toDate !== null &&
        this.toDate !== undefined &&
        this.toDate !== ""
      ) {
        params.push(`ToDate=${this.toDate}`);
      }
      if (this.openWork && !this.completedWork) {
        params.push(`TaskType=open`);
      }
      else if (!this.openWork && this.completedWork) {
        params.push(`TaskType=closed`);
      }
      else if (this.openWork && this.completedWork) {
        params.push(`TaskType=all`);
      }
      if (this.selectedSortOption === "sortPlantingYear") {
        params.push(`TasksGroup.Year=true`);
      }
      else if (this.selectedSortOption === "monthCheckbox") {
        params.push(`TasksGroup.Month=true`);
      }
      else if (this.selectedSortOption === "workTypeCheckbox") {
        params.push(`TasksGroup.WorkTypeId=true`);
      }
      else if (this.selectedSortOption === "baumId") {
        params.push(`TasksGroup.TreeId=true`);

      }
      return (
        this.inventarId +
        "?" +
        params.join("&")
      );
    },
    async fetchAllReports(itemId) {
      try {
        const response = await axios.get(
          `https://tilia-ch.rrota.org/api/Report/GetAll/`
        );
        if (itemId != null && itemId != 0) {
          this.showFullName = itemId;
          const reportObj = response.data.filter(type => type.id === itemId);
          console.log(reportObj);
          if (reportObj) {
            if (reportObj[0].reportTypeId == 1) {
              this.fromTree = reportObj[0].fromTree;
              this.toTree = reportObj[0].toTree;
              this.selectedColor = reportObj[0].colorTypeId;
              this.technicalData = reportObj[0].technicalDatas;
              this.treeColorGroup = reportObj[0].treeGroupColorType;
              //this.height = reportObj[0].sortHeight;
              //this.selectedTreeTypeId = reportObj[0].sortTreeTypeId;
              if (reportObj[0].colorTypeId == 1) {
                this.selectedColor = "red"
              }
              else if (reportObj[0].colorTypeId == 2) {
                this.selectedColor = "green"
              }
              else if (reportObj[0].colorTypeId == 3) {
                this.selectedColor = "blue"
              }
              else if (reportObj[0].colorTypeId == 4) {
                this.selectedColor = "yellow"
              }
              else if (reportObj[0].colorTypeId == 5) {
                this.selectedColor = "gray"
              } else {
                this.selectedColor = null
              }
            }
            else {
              this.pfFromTree = reportObj[0].fromTree,
                this.pfToTree = reportObj[0].toTree,
                this.fromDate = reportObj[0].fromDate,
                this.toDate = reportObj[0].toDate,
                this.workTypeId = reportObj[0].workTypeId

              //this.openWork = reportObj[0].fromTree,
              //this.completedWork = reportObj[0].fromTree,
              //this.taskSortPlantingYear = reportObj[0].sortPlantingYear,
              //this.taskSortHeight = reportObj[0].sortHeight
              //this.taskSelectedTreeTypeId = reportObj[0].sortTreeTypeId
              if (reportObj[0].taskTypeId == 1) {
                this.openWork = reportObj[0].taskTypeId,
                  this.completedWork = reportObj[0].taskTypeId
              }
              else if (reportObj[0].taskTypeId == 2) {
                this.openWork = reportObj[0].taskTypeId
              }
              else {
                this.completedWork = reportObj[0].taskTypeId
              }
              if (reportObj[0].tasksGroupMonth != null && reportObj[0].tasksGroupMonth == true) {
                this.selectedSortOption = "monthCheckbox"
              }
              else if (reportObj[0].tasksGroupYear != null && reportObj[0].tasksGroupYear == true) {
                this.selectedSortOption = "sortPlantingYear"
              }
              else if (reportObj[0].tasksGroupWorkTypeId != null && reportObj[0].tasksGroupWorkTypeId == true) {
                this.selectedSortOption = "workTypeCheckbox"
              }
              else if (reportObj[0].tasksGroupTreeId != null && reportObj[0].tasksGroupTreeId == true) {
                this.selectedSortOption = "baumId"
              }

            }
          }
        }
        this.reports = response.data;
        this.isloading = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
    },

    async exportTreeTypeReport() {
      this.isLoading = true;
      try {
        const response = await axios.get(
          `https://tilia-ch.rrota.org/api/Report/TreeTypeReport/${this.inventarId}`
        ).then(response => {
          this.isLoading = false;
          var win = window.open();
          win.document.write('<iframe src="' + response.data + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')

        }).catch(console.error);
      }
      catch (error) {
        this.isLoading = false;
        console.log(error);
        this.hasError = true;
      }
    },
  },
  created() {
    this.getAllTreeType();
    this.fetchWorkTypes();
    this.fetchAllReports();
    this.getAllTreeList(null);
  },
};
</script>
<style lang="scss">
.radio-buttons-flex-container {
  display: flex;
  /* Additional flex properties if needed */
}

.kurz-liste-title {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  color: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
}

.horizontal-line {
  background: #494949;
  height: 1px;
  width: 100%;
  margin-top: 18px;
}

.fields-gap {
  display: flex;
  gap: 25px;
}

.berichte-row {
  .berichte-holder {
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;
    padding: 0;

    .custom-label {
      display: block;
      flex-wrap: nowrap;
    }

    .from-to-tree,
    .from-to-dates {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;


    }
  }
}

.report-buttons {
  padding-top: 10px;
  border-top: 1px solid #757575;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  .submit-btn {
    width: 285px;
    color: #000;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: none;
    background-color: #1db954;
  }

  .delete-btn {
    width: 285px;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: none;
    background-color: #c82210;
  }

  .cancel-btn {
    width: 285px;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: none;
    background-color: transparent;
    border: 1px solid #fff;
  }
}

.tab-item {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  color: white !important;
}

.underline-text {
  font-size: 16px;
  line-height: 24px;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  text-decoration: underline;
}

.hidden-text-paragraf {
  display: none;
}

// .v-input{
//   flex: 1 1 auto;
// }</style>